<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/pass.png" class="icon" />
            <input type="number" oninput="if(value.length > 4)value = value.slice(0, 4)" v-model="password" class="input" placeholder="请输入4位数字" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    let { stie_code, client_id } = this.$route.query;
    this.stie_code = stie_code;
    this.client_id = client_id;   
  },
  data() {
    return {
      stie_code: '',
      client_id: '',
      password: ''
    };
  },
  methods: {
    async submit() {

      //Dialog({ message: 'pw:'+ this.password+',stie_code:'+ this.stie_code+',client_id:'+ this.client_id});
      if(isEmpty(this.password)) {
        Dialog({ message: '请输入密码' });
      }else{
        let { data } = await this.axios.post(
          '/ctid/tlService/spmw_mm',
          qs.stringify({
            pw: this.password,
            stie_code: this.stie_code,
            client_id: this.client_id
          })
        );
        Toast(data.message);
        this.$router.replace('/user/my');        
      }
     
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 229px;
}
.content {
  margin-top: 60px;
}

</style>